import "../styles/globals.css";
import "../styles/text-editor.css";
import "../styles/events.css";
import type { AppProps } from "next/app";
import { MantineProvider } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import { ModalsProvider } from "@mantine/modals";
import { WarningModal } from "../components/warning-modal";
import { SessionProvider } from "next-auth/react";
import { Session } from "next-auth";
import Head from "next/head";
import Layout from "../components/layout";
import {
  Provider as RollbarProvider,
  ErrorBoundary,
  useRollbar,
} from "@rollbar/react";
import Rollbar from "rollbar";
import { useEffect } from "react";

const RollbarEventListnerComponent = () => {
  const rollbar = useRollbar();

  useEffect(() => {
    const handleError = (error) => {
      console.error("Unhandled error:", error);
      console.error("Unhandled error trace:", error.error.stack);
      // Optional: Log to Rollbar
      rollbar.error(error);
    };

    window.addEventListener("error", handleError);
    return () => window.removeEventListener("error", handleError);
  }, []);

  return <></>;
};

function MyApp({ Component, pageProps }: AppProps<{ session: Session }>) {
  const FallbackUI = () => <div>Oops, something went wrong</div>;

  const rollbarConfig: Rollbar.Configuration = {
    accessToken: process.env.NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    ignoreDuplicateErrors: true,
    captureLambdaTimeouts: true,
    captureDeviceInfo: true,
    includeItemsInTelemetry: true,
    nodeSourceMaps: true,
    stackTraceLimit: 100,
    captureUsername: true,
    addErrorContext: true,
    autoInstrument: true,
    enabled: process.env.NODE_ENV === "production",
    version: "1.0.0",
    codeVersion: "1.0.0",
    payload: {
      client: {
        javascript: {
          code_version: "1.0.0",
          source_map_enabled: true,
          guess_uncaught_frames: true,
        },
      },
    },
  };

  return (
    <RollbarProvider config={rollbarConfig}>
      <ErrorBoundary
        fallbackUI={FallbackUI}
        callback={(err) => console.log("error", err)}
      >
        <RollbarEventListnerComponent />
        <MantineProvider
          withGlobalStyles
          withNormalizeCSS
          theme={{
            fontFamily: "Montserrat",
            breakpoints: {
              xs: 390,
              md: 900,
              lg: 1100,
            },
          }}
        >
          <Head>
            <link rel="shortcut icon" href="favicon.ico" />
          </Head>
          <NotificationsProvider
            position="bottom-center"
            zIndex={2077}
            sx={{
              width: "100vw",
              maxWidth: "none",
              left: "50%",
              bottom: "16px",
              transform: "translateX(-50%)",
              position: "fixed",
              boxSizing: "border-box",
            }}
          >
            <ModalsProvider modals={{ warning: WarningModal }}>
              <SessionProvider session={pageProps.session}>
                <Layout>
                  <Component {...pageProps} />
                </Layout>
              </SessionProvider>
            </ModalsProvider>
          </NotificationsProvider>
        </MantineProvider>
      </ErrorBoundary>
    </RollbarProvider>
  );
}

export default MyApp;
