export const Colours = {
  darkBlue: "#002B83",
  customGreen: "#1F6521",
  lightGrey: "#BFBFBF",
  darkGrey: "#707070",
  customRed: "#D00000",
  lightBlue: "#F8F5FF",
  customBlue: "#1CA2DC",
  white: "#FFFFFF",
  black: "#000000",
  customGreyBlue: "#f6f7fe",
  customOrange: "#FF8C00",
  goodGreen: "#96AB09",
  excellentGreen: "#1F6521",
  abnormalRed: "#C2051C",
  background: "#F4F5FF",
  darkerBackground: "#E6E7F7",
  lightGreen: "#2e9930",
  lightLavender: "#f8f5ff",
  customPurple: "#8E44AD",
  wellness: {
    low: "#c22e35",
    average: "#8786e0",
    high: "#1F6521",
    illegal: "yellow",
  },
};
